import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import { Layout, Seo } from "components/common"
import { device, useMeta } from "utils"

import HomeWave from "src/components/sections/home/HomeWave"
// import { useRecoilValue } from "recoil"
// import { waveThemeState } from "src/atoms"

const IndexPage = ({ data: { home } }) => {
  // const sections = useContentfulSection(home.sections)

  const meta = useMeta(home)

  // const { constructionTextColor } = useRecoilValue(waveThemeState)

  return (
    <Layout hideFooter>
      <Seo {...meta} />
      <Wrap>
        <HomeWave />

        {/* <ConstructionText color={constructionTextColor}>
          <p>FULL SITE</p>
          <p>UNDER CONSTRUCTION</p>
        </ConstructionText> */}
      </Wrap>
    </Layout>
  )
}

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
`

// const ConstructionText = styled.div`
//   position: absolute;
//   left: 60px;
//   bottom: 40px;
//   z-index: 2;

//   display: none;

//   @media ${device.laptop} {
//     display: inline-block;
//   }

//   p {
//     font-size: 0.8rem;
//     line-height: 16px;
//     font-weight: 600;
//     letter-spacing: 0.04em;
//     color: ${({ color }) => color};
//   }
// `

export const query = graphql`
  {
    home: contentfulPage(pageId: { eq: "home" }) {
      ...Page
    }
  }
`
export default IndexPage
