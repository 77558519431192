import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import { Layout, Seo } from "components/common"
import { useMeta } from "utils"

import ContactWave from "src/components/sections/contact/ContactWave"

export default function ContactPage({ data: { contact } }) {
  const meta = useMeta(contact)

  const section = contact.sections[0]

  return (
    <Layout hideFooter>
      <Seo {...meta} />
      <Wrap>
        <ContactWave {...section} />
      </Wrap>
    </Layout>
  )
}

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
`

export const query = graphql`
  {
    contact: contentfulPage(pageId: { eq: "contact" }) {
      ...Page
    }
  }
`
